import USBLink from '@usb-shield/react-link'
import styles from '@/components/ProductsArrayWrapper/productsArrayWrapper.module.scss'
import React, { useEffect, useState } from 'react'
import { USBColumn } from '@usb-shield/react-grid'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import { useCookies } from 'react-cookie'

let cardColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
  justify: 'start',
  align: 'start',
}
let filterCardColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
  justify: 'start',
  align: 'start',
}

const ProductCard = ({ tiles, displayImage, levelUpHeads, filter }: any) => {
  const [locale, setLocale] = useState<string>(process.env.DEFAULT_LOCALE)
  const [cookies] = useCookies(['locale'])
  useEffect(() => {
    const localeFromCookies = cookies.locale || process.env.DEFAULT_LOCALE
    setLocale(localeFromCookies)
  }, [])
  
  const getAttributeName = (value: any) => {
    if (value.hasOwnProperty('genericCTAText')) {
      return 'genericCTAText'
    }
    return value.hasOwnProperty('ctaSummaryCardText')
    ? 'ctaSummaryCardText'
    : ''
  }

  const getLevelHeads = (value: any) => {
    const customHeadTopClass =
      displayImage === false ? styles.topHeadLayout : ''
    if (levelUpHeads) {
      return (
        filter ? 
        <h4 className={styles.headH3 + ' ' + customHeadTopClass}>
          {value.name}
        </h4> : 
        <h3 className={styles.headH3 + ' ' + customHeadTopClass}>
          {value.name}
        </h3>
      )
    } else {
      return (
        <h2 className={styles.headH3 + ' ' + customHeadTopClass}>
          {value.name}
        </h2>
      )
    }
  }
  return tiles.length > 0 && tiles?.map((value: any, index: any) => {
    // returning if product name is empty to avoid empty product cards
    if (!value.name) return

    let ctaLink 
    // add locale to overview link if geolocation enabled and locale is not en-us
    if (process.env.FEATURE_GEOLOCATION === 'true' && locale?.toLowerCase() !== 'en-us') {
      ctaLink = '/products/' + locale + '/' + value.id + '/' + value.versionNumber

    } else {
      ctaLink = '/products/' + value.id + '/' + value.versionNumber
    }
    
    const attributeName = getAttributeName(value)
    // check for undefined displayImage value that will cause class to be left off column
    if (displayImage == null) {
      displayImage = false
    }
    const domain: string = process.env.CONTENT_DOMAIN || ''
    const customClass = displayImage === false ? styles.heightLayout : ''
    const customBottomClass = displayImage === false ? styles.marginLayout : ''
    const customTopClass = displayImage === false ? styles.topLayout : ''
    const indexValue = value?.name.toLowerCase().replace(/[-_\s.]+(.)?/g, (_: any, c: string) => c ? c.toUpperCase() : '')
    return (
      <USBColumn
        layoutOpts={filter ? filterCardColumn : cardColumn}
        className={styles.uSBCardCol + ' ' + customClass}
        key={indexValue}
      >
        <React.Fragment key={indexValue}>
          {displayImage && displayImage === true && value.featuredLogoImage && (
            <div className={styles.imageBlock}>
              <img src={domain + value.featuredLogoImage} alt="" />
            </div>
          )}
          <div className={styles.block}>
            {getLevelHeads(value)}
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: value.description }}
            />
            {attributeName && (
              <div className={styles.linkLayout + ' ' + customTopClass}>
                <USBLink
                  linkType="basic"
                  href={ctaLink}
                  dataTestId={`ctaLink-${index}`}
                  addClasses={styles.solutionAreaLink + ' ' + customBottomClass}
                  ariaProps={{ label: value.ariaLabel }}
                >
                  {value[attributeName]}
                </USBLink>
              </div>
            )}
          </div>
        </React.Fragment>
      </USBColumn>
    )
  })
}

export default ProductCard
